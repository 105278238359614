<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h1 class="font-weight-bolder">
          {{ $t('invoices') }}
        </h1>
        <b-button
          v-if="currentUser.role_name !== 'customer'"
          variant="primary"
          @click="openModal"
        >
          {{ $t('uploadInvoice') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-overlay :show="loading">
          <invoices-table
            :invoices="invoices"
            @refetch="getInvoices"
          />
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-pagination
          :value="quotesPagination.page"
          :total-rows="quotesPagination.total_objects"
          :per-page="quotesPagination.per_page"
          aria-controls="my-table"
        />
      </b-col>
    </b-row>
    <b-modal
      id="newInvoice"
      ok-only
      :ok-title="$t('save')"
      @ok="saveFiles"
    >
      <div class="text-center mb-2">
        <h3>{{ $t('uploadYourInvoice') }}</h3>
      </div>
      <b-row>
        <b-col sm="12">
          <b-form-group>
            <b-form-datepicker
              v-model="newInvoice.date"
              :placeholder="$t('date')"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col
          sm="12"
          lg="6"
        >
          <b-form-group>
            <b-form-input
              v-model="newInvoice.name"
              :placeholder="$t('name')"
              autocomplete="off"
              type="text"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          lg="6"
        >
          <b-form-group>
            <b-form-input
              v-model="newInvoice.folio"
              :placeholder="$t('folio')"
              autocomplete="off"
              type="text"
            />
          </b-form-group>
        </b-col> -->
        <b-col
          sm="12"
          class="mt-1"
        >
          <dropzone
            height="150"
            :multiple="true"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import InvoicesTable from '@/components/invoice/InvoicesTable.vue'
import Dropzone from '@/components/dropzone/Dropzone.vue'

export default {
  components: {
    InvoicesTable,
    Dropzone,
  },

  data() {
    return {
      loading: false,
      invoices: [],
      newInvoice: {
        name: '',
        folio: '',
        file: null,
        date: null,
      },
    }
  },

  computed: {
    ...mapGetters('quotes', ['quotes', 'quotesPagination']),
    ...mapGetters('projects', ['project']),
    ...mapGetters('suppliers', {
      files: 'newProviderFiles',
    }),
    ...mapGetters('auth', ['currentUser']),
  },

  created() {
    this.loading = true
    if (this.$route.name === 'quoteInvoices') {
      this.fetchQuote(this.$route.params.quoteId)
    }
    if (this.$route.name === 'purchaseOrderInvoices') {
      this.fetchPurchaseOrders(this.$route.params.quoteId)
    }
    this.getInvoices()
  },

  destroyed() {
    this.clearQuotes()
    // this.clearProject({})
  },

  methods: {
    ...mapMutations('quotes', {
      clearQuotes: 'CLEAR_QUOTES',
      changePagination: 'CHANGE_PAGINATION',
    }),
    ...mapMutations('projects', {
      clearProject: 'SET_PROJECT',
    }),
    ...mapActions('quotes', [
      'fetchQuote',
      'getQuoteInovices',
      'createQuoteInovice',
    ]),
    ...mapActions('purchaseOrders', [
      'fetchPurchaseOrders',
      'getPurchaseOrderInovices',
      'createPurchaseOrderInovice',
    ]),
    ...mapActions('projects', ['fetchProject']),

    nextPage(value) {
      this.changePagination({ field: 'page', value })
      this.loading = true
      this.fetchQuotes({
        by_project: this.$route.params.projectId,
        meta: { pagination: this.quotesPagination },
      }).then(() => {
        this.loading = false
      })
    },

    openModal() {
      this.newInvoice = {
        name: '',
        folio: '',
        file: null,
        date: new Date(),
      }
      this.$bvModal.show('newInvoice')
    },
    getInvoices() {
      this.loading = true
      if (this.$route.name === 'quoteInvoices') {
        this.fetchQuote(this.$route.params.quoteId)
        this.getQuoteInovices({ quoteId: this.$route.params.quoteId })
          .then(response => {
            this.invoices = response
          })
          .finally(() => {
            this.loading = false
          })
      } else if (this.$route.name === 'purchaseOrderInvoices') {
        this.getPurchaseOrderInovices({
          purchaseOrderId: this.$route.params.quoteId,
        })
          .then(response => {
            this.invoices = response
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    saveFiles() {
      const files = new FormData()
      this.files.forEach(file => {
        files.append('invoice_attachments[]', file)
      })
      files.append('invoiced_at', this.newInvoice.date)
      if (this.$route.name === 'quoteInvoices') {
        this.createQuoteInovice({
          quoteId: this.$route.params.quoteId,
          attachments: files,
        }).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('filesSaved'),
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
          this.getInvoices()
        })
      } else if (this.$route.name === 'purchaseOrderInvoices') {
        this.createPurchaseOrderInovice({
          purchaseOrderId: this.$route.params.quoteId,
          attachments: files,
        }).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('filesSaved'),
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
          this.getInvoices()
        })
      }
    },
  },
}
</script>

<style></style>
