<template>
  <div :style="cssProps">
    <div>
      <b-form-file
        v-model="files"
        class="dropzone"
        accept=""
        :file-name-formatter="formatNames"
        :multiple="multiple"
        placeholder="Arrastra los archivos o haz click aqui"
        drop-placeholder="Arrastra el archivo aqui"
        @input="pushfile()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {},

  props: {
    height: {
      type: String,
      default() {
        return '300'
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return false
      },
    },
  },

  data() {
    return {
      files: [],
    }
  },

  computed: {
    ...mapGetters('suppliers', {
      filesInState: 'newProviderFiles',
    }),
    cssProps() {
      return {
        '--dropzone-height': `${this.height}px`,
      }
    },
  },

  destroyed() {
    this.clearFiles()
  },

  methods: {
    ...mapMutations('suppliers', {
      addFile: 'ADD_FILE',
      setFile: 'SET_FILE',
      clearFiles: 'CLEAR_FILES',
    }),

    pushfile() {
      if (this.multiple) {
        this.files.forEach(file => {
          this.addFile(file)
        })
      } else {
        this.setFile(this.files)
      }
    },
    formatNames() {
      if (this.filesInState.length) {
        return `${this.filesInState.length} ${this.$t('files')}`
      }
      return 'Arrastra los archivos o haz click aqui'
    },
  },
}
</script>

<style lang="scss" scoped>
.dropzone {
  height: var(--dropzone-height);
  width: 100%;
  border: 3px solid #6e32b4;
  border-style: dashed;
  color: #330868;
}
</style>
