<template>
  <b-card
    class="overflow-hidden"
    no-body
  >
    <b-table
      responsive
      :items="invoices"
      :fields="fields"
    >
      <template #cell(id)="data">
        <div class="text-primary font-weight-bolder">
          <b-button
            size="sm"
            variant="primary"
            class="font-weight-bolder"
          >
            #{{ data.value }}
          </b-button>
        </div>
      </template>
      <template #cell(date)="">
        <div class="">
          {{ getInvoiceDate }}
        </div>
      </template>
      <template #cell(content_type)="data">
        <div class="text-primary font-weight-bolder">
          {{ data.value }}
        </div>
      </template>
      <template
        v-if="!noTools"
        #cell(tools)="data"
      >
        <div>
          <b-button
            variant="primary"
            class="p-0 custom-padding mr-1"
            :href="data.item.url"
            download
          >
            <feather-icon
              icon="DownloadIcon"
              class="text-white"
              size="18"
              color="#000"
            />
          </b-button>
          <!-- <b-button
            variant="primary"
            class="p-0 custom-padding"
            :href="data.item.url"
            target="_blank"
          >
            <feather-icon
              icon="EyeIcon"
              class="text-white"
              size="18"
              color="#000"
            />
          </b-button> -->
          <b-button
            v-if="isAdmin"
            variant="danger"
            class="p-0 custom-padding mr-1"
            @click="deleteInvoice(data.item.id)"
          >
            <feather-icon
              icon="XIcon"
              class="text-white"
              size="18"
              color="#000"
            />
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
  },
  props: {
    invoices: {
      type: Array,
      default() {
        return []
      },
    },
    noTools: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'Folio',
        },
        {
          key: 'filename',
          label: this.$t('name'),
        },
        {
          key: 'date',
          label: this.$t('date'),
        },
        {
          key: 'content_type',
          label: this.$t('file'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'tools',
          label: this.$t('actions'),
        },
      ],
    }
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('quotes', ['quote']),
    ...mapGetters('purchaseOrders', ['purchaseOrder']),
    isAdmin() {
      return this.currentUser.role_name === 'admin'
    },

    getInvoiceDate() {
      if (this.$route.name === 'quoteInvoices') {
        return this.quote.invoiced_at
      }
      if (this.$route.name === 'purchaseOrderInvoices') {
        return this.purchaseOrder.invoiced_at
      }
      return 'No hay fecha'
    },
  },

  methods: {
    ...mapActions('quotes', ['getQuoteInovices', 'deleteQuoteInovice']),
    ...mapActions('purchaseOrders', [
      'getPurchaseOrderInovices',
      'deletePurchaseOrderInovice',
    ]),

    deleteInvoice(invoiceId) {
      if (this.$route.name === 'quoteInvoices') {
        this.deleteQuoteInovice({
          quoteId: this.$route.params.quoteId,
          invoiceId,
        }).then(() => {
          this.$emit('refetch')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('fileDelted'),
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
        })
      } else if (this.$route.name === 'purchaseOrderInvoices') {
        this.deletePurchaseOrderInovice({
          purchaseOrderId: this.purchaseOrder.id,
          invoiceId,
        }).then(() => {
          this.$emit('refetch')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('fileDelted'),
              icon: 'SaveIcon',
              variant: 'success',
            },
          })
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-padding {
  padding: 3px !important;
}
</style>
